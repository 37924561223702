import React from 'react'
import Layout from '../components/Layout'
import Nav from '../components/Nav'

import { Link } from "gatsby"

import qbsThumb from '../images/qbs-thumb.png'
import profileThumb from '../images/profile-card-thumb.png'
import hubspotThumb from '../images/hubspot-network-thumb.png'
import mclarenHero from '../images/mclaren-hero.png'
import fxModal from '../images/shopify-modal-solution.png'
import shopifyMC from '../images/shopify-mc-checkout-3.png'

export default function work() {
    return(
        <Layout>
            <Nav></Nav>
            <section className="section--container">
                <section className="section--portfolio flex-h">
                    <div>
                        <h1>Shopify</h1>
                        <p>Senior Product Designer</p>
                        <p>I researched currency's impact on international selling strategies for multinational e-commerce companies. Out of this research, I found that alternative payment methods (e.g. AfterPay) were being blocked by the new checkout experience. I designed a solution that resulted in a 9% relative increase in order conversion rate.</p>
                        <h4>Notable accomplishments</h4>
                        <ul>
                            <li>UX work prevented unnecessary resources to be spent improving the accounting experience as previously thought.</li>
                            <li>Unblocked multi-currency payment gateways from new checkout experience.</li>
                            <li>Reverted 50% of churn from the multi-currency product by Shopify Plus merchants.</li>
                        </ul>
                    </div>
                    <div>
                        <div className="case-study--container">
                            <Link to="/case-studies/shopify/multi-currency-payment-methods"><img src={shopifyMC} alt="Shopify" /></Link>
                            <p>
                                <strong>Case Study</strong>
                                Unlocking local currency for international customers.
                                <Link to="/case-studies/shopify/multi-currency-payment-methods">Read more &rarr;</Link>
                            </p>
                        </div>
                        <div className="case-study--container">
                            <Link to="/case-studies/shopify/automatic-vs-manual-fx-rates"><img src={fxModal} alt="FX Modal" /></Link>
                            <p>
                                <strong>Case Study</strong>
                                Improving foreign exchange one modal at a time
                                <Link to="/case-studies/shopify/automatic-vs-manual-fx-rates">Read more &rarr;</Link>
                            </p>
                        </div>
                    </div>
                </section>
                <section className="section--portfolio flex-h">
                    <div>
                        <h1>HubSpot</h1>
                        <p>Senior Product Designer</p>
                        <p>HubSpot, a CRM company, created the HubSpot Network for individuals with HubSpot skills. Weeks after launch, the site reached more people than most other HubSpot niche communities on the internet. I conducted design thinking, facilitation, ideation, rapid prototyping, and user research to assess market potential and desirability.</p>
                        <h4>Notable accomplishments</h4>
                        <ul>
                            <li>Works in triad (product, engineering, design) to prioritize, triage and set strategy for the HubSpot Network</li>
                            <li>Led workshops and design sprints across organization</li>
                            <li>Co-led UX research initiative to discover unknowns</li>
                            <li>Utilized prototyping to create flows to test hypothesis with users</li>
                            <li>Network adoption met and exceeded initial estimates</li>
                        </ul>
                    </div>
                    <div>
                        <div className="case-study--container">
                            <Link to="/case-studies/hubspot/hubspot-network"><img src={hubspotThumb} alt="Design sprint" /></Link>
                            <p>
                                <strong>Case Study</strong>
                                Networking in a LinkedIn world
                                <Link to="/case-studies/hubspot/hubspot-network">Let's connect &rarr;</Link>
                            </p>
                        </div>
                    </div>
                </section>
                <section className="section--portfolio flex-h">
                    <div>
                        <h1>Atlassian</h1>
                        <p>UX Designer</p>
                        <p>I built an award-winning online community for Atlassian customers and partners. I shipped the community and continuously added features that improved customer value and business metrics. Working collaboratively on an agile team, I established a design process focused on customer feedback, prototyping, and frequent shipping.</p>
                        <h4>Notable accomplishments</h4>
                        <ul>
                            <li>Evaluative research to accompany generative research</li>
                            <li>Designed the core functionality of the community</li>
                            <li>Designed the activity feed</li>
                            <li>Configure email subscriptions settings</li>
                            <li>Advanced search</li>
                            <li>@mentions</li>
                            <li>Search alerts</li>
                            <li>RSS Feeds for individual questions and discussions</li>
                            <li>Previews for article writers</li>
                            <li>Redesigning global navigation</li>
                            <li>Community Feed aka personalized feed on homepage</li>
                            <li>Localized ad for in-person events</li>
                            <li>Online groups feature</li>
                            <li>Enhanced profile pages</li>
                            <li>Removed friction from asking a question in community</li>
                            <li>Improvements to the accept answer button</li>
                            <li>Likes on comments</li>
                            <li>Implement OpenID connect for SSO login</li>
                            <li>Improved category selection when posting to the community</li>
                            <li>Initiated and led the Experimentation/growth program</li>
                            <li>Ran over 50+ experiments on various parts of the community</li>
                            <li>Atlassian Documentation integration</li>
                            </ul>
                    </div>
                    <div>
                        <div className="case-study--container">
                            <Link to="/case-studies/atlassian/personalized-question-asking-experience"><img src={qbsThumb} alt="QBS" /></Link>
                            <p>
                                <strong>Case Study</strong>
                                Improving question asking experience with personalized data
                                <Link to="/case-studies/atlassian/personalized-question-asking-experience">Read on &rarr;</Link>
                            </p>
                        </div>
                        <div className="case-study--container">
                            <Link to="/case-studies/atlassian/what-goes-into-trusting-an-answer-er"><img src={profileThumb} alt="" /></Link>
                            <p>
                                <strong>Case Study</strong>
                                What goes into trusting an answer?
                                <Link to="/case-studies/atlassian/what-goes-into-trusting-an-answer-er">Read on &rarr;</Link>
                            </p>
                        </div>
                    </div>
                </section>
                <section className="section--portfolio flex-h">
                    <div>
                        <h1>Amazon</h1>
                        <p>UX Designer</p>
                        <p>I prototyped a new comparison tool for electronic products on Amazon.com that resulted in a 30% reduction in time to purchase. Working collaboratively with the Amazon Search team, I utilized user research to inform new experiences that were later prototyped and tested with actual Amazon customers.</p>
                        <h4>Notable accomplishments</h4>
                        <ul>
                            <li>Successfully designed a path forward for customers to decrease time spent on comparison shopping that resulted in lower degree of difficulty scores as well as higher sentiment ratings.</li>
                            <li>Worked on a program to help educate consumers of products that held an approval status that works with the Amazon ecosystem</li>
                        </ul>
                    </div>
                    <div>
                        <div className="case-study--container">
                            <Link to="/case-studies/amazon/comparison-shopping"><img src={mclarenHero} alt="Project McLaren" /></Link>
                            <p>
                                <strong>Case study</strong>
                                Improving comparison shopping on Amazon.com
                                <Link to="/case-studies/amazon/comparison-shopping">Read on &rarr;</Link>
                            </p>
                        </div>
                    </div>
                </section>
                <section className="section--portfolio">
                    <div style={{ width: '40vw'}}>
                        <h1>Spiceworks</h1>
                        <p>Senior Designer</p>
                        <p>I led new digital experiences for the marketing team, bridging the gap between brand and product design systems. By pushing boundaries, I aligned the product with the brand culture, benefiting our users. I also introduced a shift from PDF-based annual reports to fast-loading web pages using AngularJS and tracked performance with Google Analytics. I demonstrated the value of online reports compared to traditional PDF whitepapers.</p>
                        <h4>Notable accomplishments</h4>
                        <ul>
                            <li>Led illustration system effort for multiple user conferences that included print, large-scale signage, exhibition space, and digital assets</li>
                            <li>Created the first digital format of research white papers for marketers</li>
                            <li>Owned art direction for multiple digital marketing campaigns</li>
                            <li>Assisted on corporate keynotes for company leadership</li>
                        </ul>
                    </div>
                </section>
            </section>
            
        </Layout>
    )
}